module.exports = {
  siteTitle: 'Cássia Santos | English Made Simple', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Cássia S.', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  pathPrefix: `/gatsby-starter-identity/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Cássia Santos',
  heading: 'English Teacher',
  about: 'Are you ready to learn English at a simple way? Take a placement test, it’s free!',
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:teacher@cassia.education',
    },
    {
      icon: 'fa-whatsapp',
      name: 'WhatsApp',
      url: 'https://wa.me/5511953657845?text=Ol%C3%A1%2C+tenho+interesse+em+ter+aulas+de+ingl%C3%AAs',
    },
    {
      icon: 'fa-telegram',
      name: 'Telegram',
      url: 'https://t.me/thisiscassia',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/cassia.education/'
    },
  ],
};
